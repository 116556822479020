/*
  ____                _                                         _
 | __ )   __ _   ___ | | __ __ _  _ __  ___   _   _  _ __    __| |
 |  _ \  / _` | / __|| |/ // _` || '__|/ _ \ | | | || '_ \  / _` |
 | |_) || (_| || (__ |   <| (_| || |  | (_) || |_| || | | || (_| |
 |____/  \__,_| \___||_|\_\\__, ||_|   \___/  \__,_||_| |_| \__,_|
                           |___/
*/

.TeamGridLayer {
    grid-template-areas: ". . . ." ". . NavBar ." ". . . ." ". TitleText TitleText ." ". SubTitleText SubTitleText ." ". Arrow Arrow ." ". . . ." "TeamBody TeamBody TeamBody TeamBody";
}

.TeamAccentLayer {
    top: 0;
    height: 100%;
    width: 100vw;
    background: #E00038;
    opacity: .1;
    z-index: 1;
}

/*
   ____               _                _
  / ___| ___   _ __  | |_  ___  _ __  | |_
 | |    / _ \ | '_ \ | __|/ _ \| '_ \ | __|
 | |___| (_) || | | || |_|  __/| | | || |_
  \____|\___/ |_| |_| \__|\___||_| |_| \__|

*/

.TeamBodyPage {
    grid-area: TeamBody;
    width: 100%;
    height: 100%;
    background: rgba(114, 17, 33, 0.1);
    display: grid;
    grid-template-columns: 5% 42.5% 5% 42.5% 5%;
    grid-template-rows: 5% 87% 8%;
    gap: 1px 1px;
    grid-template-areas: ". . . . ." ". ViniTeamSection . EeshanTeamSection ." ". . . . .";
    font-family: Roboto-regular, sans-serif;
}

.ViniTeamSection {
    position: relative;
    grid-area: ViniTeamSection;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    background: #f3f3f39e;
    border-radius: 20px;
}

.EeshanTeamSection {
    grid-area: EeshanTeamSection;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    background: #f3f3f39e;
    border-radius: 20px;
}

.EeshanTeamImages {
    margin: 6% 6% 0;
    position: relative;
    width: 88%;
    height: fit-content;
    display: grid;
    grid-template-columns: 50% 10px auto;
    grid-template-rows: 1fr;
    gap: 1px 1px;
    grid-template-areas: "EeshanPhoto . MIT";
}

.ViniTeamImages {
    margin: 6% 6% 0;
    position: relative;
    width: 88%;
    height: fit-content;
    display: grid;
    grid-template-columns: 50% 10px auto;
    grid-template-rows: 1fr 10px 1fr;
    gap: 1px 1px;
    grid-template-areas: "ViniPhoto . Cornell" "ViniPhoto . ." "ViniPhoto . Tech";
}


.TeamPhoto {
    position: relative;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    left: 50%;
    top: 50%;
    border-radius: 20px;
    /*border: 0px solid #11304a;*/
    transform: translate(-50%, -50%);
}

.TeamLogo {
    position: absolute;
    width: 60%;
    height: 100%;
    object-fit: contain;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;

}

.ViniPhoto {
    grid-area: ViniPhoto;
}

.EeshanPhoto {
    grid-area: EeshanPhoto;
}

.CornellTeamLogo {
    grid-area: Cornell;
}

.TechTeamLogo {
    grid-area: Tech;
}

.MITTeamLogo {
    grid-area: MIT;
}

.TeamTitle {
    margin: 5% 6% 0;
    width: 88%;
    text-align: center;
    white-space: nowrap;
    font-size: 3vw;
    font-family: Roboto-900, sans-serif;
    color: #593657;
}

.TeamText {
    flex-grow: 1;
    overflow: auto;
    margin: 1% 6% 6%;
    font-size: 17px;
    color: #7E4E7C;
    line-height: 1.6;
}

.TeamText ul {
    list-style-type: disc;
}

.TeamText a {
    font-family: Roboto-regular-italic, sans-serif;
    font-style: italic;
    text-decoration: none;
    color: #1b5d92;
}

.TeamText a:hover {
    text-decoration: underline;
    color: #2580c7;
}


/*
  _           _    _
 | |     ___ | |_ | |_  ___  _ __  ___
 | |    / _ \| __|| __|/ _ \| '__|/ __|
 | |___|  __/| |_ | |_|  __/| |   \__ \
 |_____|\___| \__| \__|\___||_|   |___/

 */
.TeamGridLayer > .PageTitle {
    height: 63%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.TeamGridLayer > .PageSubTitle {
    color: rgba(255, 255, 255, 0.9);
    /*border: 3px solid rgba(255, 255, 255, 0.8);*/
    background: rgba(143, 0, 36, 0.5);
    font-size: 3.2vw;
}

.TeamGridLayer > .PageTitle > #team_letters {
    fill: #8F0024;
}


.TeamGridLayer > .PageTitle:hover #team_letters {
    /*font-family: Roboto-regular, sans-serif;*/
    /*fill: rgba(255, 255, 255, 0.42);*/
    stroke: #8F0024;
    stroke-width: .2vw;
    stroke-miterlimit: 10;
    overflow: visible;
    animation: letters_transition_color 2s ease forwards;
    animation-delay: 1s;
    /*animation-play-state: running;*/
}

@keyframes letters_transition_color {
    to {
        fill: rgba(0, 21, 49, 0.1);
        stroke: white;
    }
}

#team_letters path:nth-child(1) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1118;
}

#team_letters path:nth-child(2) {
    stroke-dasharray: 300;
    stroke-dashoffset: 970;
}

#team_letters path:nth-child(3) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1203;
}

#team_letters path:nth-child(4) {
    stroke-dasharray: 300;
    stroke-dashoffset: 708;
}

#team_letters path:nth-child(5) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1076;
}

#team_letters path:nth-child(6) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1038;
}

#team_letters path:nth-child(7) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1648;
}


@media only screen and (max-width: 800px) {
    .TeamGridLayer {
        width: 100vw;
        height: 200vh;
        grid-template-columns: 6% 88% 6%;
        /*grid-template-rows: 15% 4.5% 7.5% 2.5% 10% 10.5% 50%;*/
        grid-template-rows: 20% 5% 10% 2.5% 10% 2.5% 50%;
        gap: 1px 1px;
        grid-template-areas: ". . ." "TitleText TitleText TitleText" ". SubTitleText ."  ". . ." ". NavBar ." ". . ." "TeamBody TeamBody TeamBody";
    }

    .TeamAccentLayer {
        background: #950d2e;
    }

    .TeamGridLayer > .PageTitle {
        position: absolute;
        width: 90%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }

    .TeamGridLayer > .PageSubTitle {
        width: fit-content;
        height: fit-content;
        font-size: 2.4vh;
    }

    .TeamBodyPage {
        grid-template-columns:  5% 90% 5%;
        grid-template-rows: 3% 46% 2% 46% 3%;
        gap: 1px 1px;
        grid-template-areas: ". . ." ". ViniTeamSection . " ". . ." ". EeshanTeamSection ." ". . .";
    }

    .ViniTeamSection, .EeshanTeamSection {
        height: 100%;
    }

    .TeamTitle {
        font-size: 28px;
    }

    .TeamText {
        margin-top: 2%;
    }
}
